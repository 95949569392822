import React from 'react'
import Button from 'react-bootstrap/Button'
import cleaningAreas from '../../quoteUtils/cleaningAreas';
import { Field } from 'formik';

// Media
import oven from '../../../media/icons/oven.png';
import refrigerator from '../../../media/icons/refrigerator.png';
import window from '../../../media/icons/window.png';
import cabinets from '../../../media/icons/cabinets.png';
import sofa from '../../../media/icons/sofa.png';

const Extras = ({setFieldValue, values}) => {
    const icons = {
        oven: oven,
        refrigerator: refrigerator,
        windows: window,
        cabinets: cabinets,
        furniture: sofa,
    }

    return (
        <>
            <p>Select the number of items you'd like cleaned</p>
            <div className='quote--withQuantity quote--extras'>
                { cleaningAreas.extrasWithQuantity.map(item => (
                    <div key={`${item.name}-withQuantity`}>
                        <div className='quote--icon'>
                            <img src={icons[item.name]} alt={`${item.name} icon`} />
                        </div>
                        <p className='fw-600'>{item.label}</p>
                        <div className='quote--quantity'>
                            { values[item.name] === 0 ?
                                <Button variant='primary' size='sm' disabled aria-label={`Decrease ${values[item.label]} Count`} >
                                    <i className="bi bi-dash-lg"></i>
                                </Button>
                                :<Button variant='primary' size='sm'
                                    aria-label={`Decrease ${values[item.label]} Count`}
                                    onClick={() => setFieldValue(item.name, Math.max(0, values[item.name] - 1))}
                                >
                                    <i className="bi bi-dash-lg"></i>
                                </Button>
                            }
                            <p>{values[item.name]}</p>
                            { values[item.name] === item.max ?
                                <Button variant='primary' size='sm' disabled aria-label={`Increase ${values[item.label]} Count`} >
                                    <i className="bi bi-plus-lg"></i>
                                </Button>
                                :<Button variant='primary' size='sm'
                                    aria-label={`Increase ${values[item.label]} Count`}
                                    onClick={() => setFieldValue(item.name, Math.min(item.max, values[item.name] + 1))}
                                >
                                    <i className="bi bi-plus-lg"></i>
                                </Button>
                            }
                        </div> 
                    </div>
                ))}
                { cleaningAreas.extrasWithoutQuantity.map(item => (
                    <div key={`${item.name}-withoutQuantity`}>
                        <div className='quote--icon'>
                            <img src={icons[item.name]} alt={`${item.name} icon`} />
                        </div>
                        <div className='my-3 form-check center-self'>
                            <Field type="checkbox" name={item.name}  className='form-check-input' id={item.name} />
                            <label htmlFor={item.name} className='fw-600'>{item.label}</label>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Extras;