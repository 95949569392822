import React from 'react'
import Container from 'react-bootstrap/Container';
import PageTitle from '../components/PageTitle';

// Media
import bathroom from '../../media/icons/bathroom.png';
import bedrooom from '../../media/icons/bedroom.png';
import powder_room from '../../media/icons/powder-room.png';
import oven from '../../media/icons/oven.png';
import refrigerator from '../../media/icons/refrigerator.png';
import window from '../../media/icons/window.png';
import cabinets from '../../media/icons/cabinets.png';
import sofa from '../../media/icons/sofa.png';


const Credits = () => {
    const icons = {
        bathrooms: bathroom,
        bedrooms: bedrooom,
        powder_rooms: powder_room,
        oven: oven,
        refrigerator: refrigerator,
        windows: window,
        cabinets: cabinets,
        furniture: sofa,
    }
    
    const formatIcons = [
        {
            name: 'bedrooms',
            creatorName: 'Awsome',
            creator: 'https://thenounproject.com/creator/kirantravelling2/',
        },
        {
            name: 'bathrooms',
            creatorName: 'mangunkarsa',
            creator: 'https://thenounproject.com/creator/mangunkarsa/',
        },
        {
            name: 'powder_rooms',
            creatorName: 'Tim Boelaars',
            creator: 'https://thenounproject.com/creator/timboelaars/',
        },
        {
            name: 'oven',
            creatorName: 'DAVIVONGSA PATHRPOL',
            creator: 'https://thenounproject.com/creator/davipath/',
        },
        {
            name: 'refrigerator',
            creatorName: 'DAVIVONGSA PATHRPOL',
            creator: 'https://thenounproject.com/creator/davipath/',
        },
        {
            name: 'windows',
            creatorName: 'Iconspark',
            creator: 'https://thenounproject.com/creator/iconsparks/',
        },
        {
            name: 'cabinets',
            creatorName: 'Candy Design',
            creator: 'https://thenounproject.com/creator/pakcandydesign/',
        },
        {
            name: 'furniture',
            creatorName: 'Hanbai',
            creator: 'https://thenounproject.com/creator/hanbaicon/',
        },
    ]

    const source = 'http://www.thenounproject.com/'
    const sourceName = 'The Noun Project'

    const license = 'https://creativecommons.org/licenses/by/3.0/'
    const licenseName = 'CC BY-3.0 Attribution License'

  return (
    <div>
        <Container>
            <PageTitle title='DHCS - Credits' />

            <h1>Credits</h1>
            {
                formatIcons.map( item => (
                    <div key={item.name} className='m-5'>
                        <div className='quote--icon'>
                            <img src={icons[item.name]} alt={`${item.name} icon`} />
                        </div>
                        <p>
                            Icon by <a href={item.creator} className='link'>{item.creatorName}</a>,  cropped,
                            from <a href={source} className='link'>{sourceName}</a>, 
                            licensed under <a href={license} className='link'>{licenseName}</a>
                        </p>
                    </div>
                ))
            }
        </Container>
    </div>
  )
}

export default Credits
