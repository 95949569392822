import React from 'react'
import { QuoteContext } from '../../pages/Quote'
import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button'
import formData from '../../quoteUtils/formData';
import cleaningAreas from '../../quoteUtils/cleaningAreas';
import validationSchema from '../../quoteUtils/validationSchema';
import initialValues from '../../quoteUtils/initialValues';
import axios from 'axios';
import WithQuantity from './WithQuantity';
import WithoutQuantity from './WithoutQuantity';
import Extras from './Extras' 

const QuoteForm = () => {
    const { setQuoteSubmitted, setResponseData} = React.useContext(QuoteContext);
    const [error, setError] = React.useState(null);

    function handleZipCodeChange(e, setFieldValue) {
        const { value } = e.target;
        if (/^\d*$/.test(value) && value.length <= 5) {
            setFieldValue('zip_code', value);
        }
    }

    function handlePhoneChange(e, setFieldValue) {
        const { value } = e.target;
        if (/^[\d-]*$/.test(value) && value.length <= 12) {
            setFieldValue('phone', value);
        }
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/calculate-quote/`, values);
            setResponseData(response.data);
            setError(null);
            setQuoteSubmitted(true);
        } catch (error) {
            if (error.response.data.non_field_errors && error.response.data.non_field_errors[0] === 'EMPTY'){
                setError('To receive a quote, please select at least one item or room for cleaning.');
            } else if (error.response.data.non_field_errors){
                setError(error.response.data.non_field_errors[0])
            } else {
                setError('Error calculating quote. Please try again later.');
            }
            setResponseData(null);
        } finally {
            setSubmitting(false);
        }
    };

    return( 
        <Formik
            initialValues = {initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            >
            {({ values, setFieldValue, isSubmitting, errors, touched, dirty, isValid }) => (
                <Form className='quote--form'>
                    {/* Hidden inputs */}
                    { cleaningAreas.withQuantity.map(item => (
                        <Field type='hidden' name={item.name} key={item.name} />
                    ))}
                    { cleaningAreas.extrasWithQuantity.map(item => (
                        <Field type='hidden' name={item.name} key={item.name} />
                    ))}

                    <h2 className='fw-500 text-center mt-3'>Room Selection & Cleaning Type</h2>
                    <WithQuantity setFieldValue={setFieldValue} values={values} />
                    <WithoutQuantity errors={errors} touched={touched} />
                    <h2 className='fw-500 text-center'>Extras</h2>
                    <Extras setFieldValue={setFieldValue} values={values} />
                    <hr />

                    {/* Frequency */}
                    <section className='my-5'>
                        <label htmlFor="frequency" className='mb-3'><h2>Frequency</h2></label>
                        <Field
                            as="select"
                            id="frequency"
                            name="frequency"
                            className={`form-control ${touched.frequency && errors.frequency ? 'is-invalid' : ''}`}
                        >
                            <option value="">Select a freqeuncy</option>
                            {formData['frequency'].map((val, index) => (
                                <option key={val || index} value={val}>
                                    {val}
                                </option>
                            ))}
                        </Field>
                        <ErrorMessage name='frequency'>
                            {msg => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                    </section>

                    {/* Homeowner */}
                    <section className='my-5'>
                        <h2 className='mb-3'>Homeowner Information</h2>
                        <div>
                            <Row>
                                <Col sm={12} md={6} >
                                    <label htmlFor='firstname'>First Name</label>
                                    <Field id='firstname' name='firstname'  className={`form-control ${touched.firstname && errors.firstname ? 'is-invalid' : ''}`} />
                                    <ErrorMessage name='firstname'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                                <Col sm={12} md={6} >
                                    <label htmlFor='lastname'>Last Name</label>
                                    <Field id='lastname' name='lastname'  className={`form-control ${touched.lastname && errors.lastname ? 'is-invalid' : ''}`} />
                                    <ErrorMessage name='lastname'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} md={6} >
                                    <label htmlFor='email'>Email</label>
                                    <Field id='email' name='email' type='email' className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`} />
                                    <ErrorMessage name='email'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                                <Col sm={12} md={6}>
                                    <label htmlFor='phone'>Phone</label>
                                    <Field 
                                        id='phone' 
                                        name='phone'  
                                        className={`form-control ${touched.phone && errors.phone ? 'is-invalid' : ''}`}
                                        onChange={(e) => handlePhoneChange(e, setFieldValue)}
                                        placeholder='123-456-7890'
                                    />
                                    <ErrorMessage name='phone'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            </Row>   
                        </div>
                    </section>

                    {/* Address */}
                    <section className='my-5'>
                        <h2 className='mb-3'>Address Information</h2>
                        <div>
                            <Row>
                                <Col sm={12} md={6} >
                                    <label htmlFor='address'>Address</label>
                                    <Field 
                                        id='address' 
                                        name='address'  
                                        className={`form-control ${touched.address && errors.address ? 'is-invalid' : ''}`} 
                                    />
                                    <ErrorMessage name='address'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                                <Col sm={12} md={6} >
                                    <label htmlFor='apt'>Apt/Suite</label>
                                    <Field 
                                        id='apt' 
                                        name='apt'  
                                        className={`form-control ${touched.apt && errors.apt ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name='apt'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} md={4} >
                                    <label htmlFor='city'>City</label>
                                    <Field 
                                        id='city' 
                                        name='city' 
                                        className={`form-control ${touched.city && errors.city ? 'is-invalid' : ''}`} 
                                    />
                                    <ErrorMessage name='city'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                                <Col sm={12} md={4} >
                                    <label htmlFor='state'>State</label>
                                    <Field
                                        as="select"
                                        id="state"
                                        name="state"
                                        className={`form-control ${touched.state && errors.state ? 'is-invalid' : ''}`}
                                    >
                                        <option value="">Select a state</option>
                                        {formData['states'].map((state, index) => (
                                            <option key={state.abbreviation || index} value={state.abbreviation}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name='state'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                                <Col sm={12} md={4} >
                                    <label htmlFor='zip_code'>Zip Code</label>
                                    <Field 
                                        id='zip_code' 
                                        name='zip_code'  
                                        className={`form-control ${touched.zip_code && errors.zip_code ? 'is-invalid' : ''}`} 
                                        onChange={(e) => handleZipCodeChange(e, setFieldValue)}
                                    />
                                    <ErrorMessage name='zip_code'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            </Row>           
                        </div>
                    </section>

                    {/* Other */}
                    <section className='my-5'>
                        <h2 className='mb-3'>Other</h2>
                        <div>
                            <Row>
                                <Col>
                                    <label htmlFor='additional'>Additonal Info</label>
                                    <Field 
                                        id='additional' 
                                        name='additional' 
                                        as='textarea'  
                                        className={`form-control ${touched.additional && errors.additional ? 'is-invalid' : ''}`} 
                                        maxLength={250} 
                                    />
                                    <ErrorMessage name='additional'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='quote--select'>
                                    <label htmlFor="about_us">How did you hear about us?</label>
                                    <Field
                                        as="select"
                                        id="about_us"
                                        name="about_us"
                                        className={`form-control ${touched.about_us && errors.about_us ? 'is-invalid' : ''}`}
                                    >
                                        {formData['about_us'].map((val) => (
                                            <option key={val} value={val}>
                                                {val}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name='about_us'>
                                        {msg => <div className="text-danger">{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            </Row>
                        </div>

                        <p className='mb-3'>Have any questions? <Link to='/contact-us' className='link'>Contact us!</Link></p>
                        <p>By continuing to receive a quote, you agree to 
                            our <Link to='/terms' className='link'>Terms & Conditions</Link> and <Link to='/privacy-policy' className='link'>Privacy Policy</Link>, and 
                            confirm that you are at least 18 years of age.</p>
                    </section>

                    {/* Submit */}
                    {error && <p className='text-danger text-center'>{error}</p>}
                    <div className='quote--button'>
                        <Button type='submit' variant='outline-primary' disabled={isSubmitting || !dirty}>
                            {isSubmitting ? 'Getting Quote...' : 'Get Quote'}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik> 
    )
}

export default QuoteForm;