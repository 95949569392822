const cleaningAreas = {
    withQuantity: [
        {
            name: 'bedrooms',
            label: 'Bedrooms',
            iconUrl: 'icons/bedroom.png',
            min:0,
            max: 10
        },
        {
            name: 'bathrooms',
            label: 'Bathrooms',
            iconUrl: 'icons/bathroom.png',
            min:2,
            max: 5
        },
        {
            name: 'powder_rooms',
            label: 'Half-Bathrooms',
            iconUrl: 'icons/powder-room.png',
            min:0,
            max: 2
        },
    ],
    withoutQuantity: [
        {
            name: 'kitchen',
            label: 'Kitchen',
            type: 'always'
        },
        {
            name: 'dining_room',
            label: 'Dining Room',
        },
        {
            name: 'living_room',
            label: 'Living Room',
        },
        {
            name: 'family_room',
            label: 'Family Room',
        },
        {
            name: 'eat_in',
            label: 'Eat-in Area',
        },
        {
            name: 'office_study',
            label: 'Office/Study Room',
        },
        {
            name: 'basement',
            label: 'Basement',
        },
        {
            name: 'laundry_room',
            label: 'Laundry Room',
        },
        {
            name: 'sun_room',
            label: 'Sun Room',
        },
    ],
    onlyStandard: [
        {
            name: 'hallway',
            label: 'Hallway',
        },
        {
            name: 'stairs',
            label: 'Stairs',
        },
    ],
    extrasWithQuantity: [
        {
            name: 'oven',
            label: 'Oven (Inside)',
            max: 2,
        },
        {
            name: 'refrigerator',
            label: 'Refrigerator (Inside)',
            max: 2,
        },
        {
            name: 'windows',
            label: 'Windows',
            max: 50,
        },
        {
            name: 'furniture',
            label: 'Furniture/Upholstery (Vacuum)',
            max: 10
        },
    ],
    extrasWithoutQuantity: [
        {
            name: 'cabinets',
            label: 'Cabinets',
        },
    ]
}
export default cleaningAreas;