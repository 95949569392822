const formData = {
    cleaning_type: [
        {
            value: 'standard',
            label: 'Standard Clean'
        },
        {
            value: 'deep',
            label: 'Deep Clean'
        },
        {
            value: 'move-in/out',
            label: 'Move In/Out Clean'
        },
    ],
    cleaning_type_none: [
        {
            value: 'none',
            label: 'None'
        },
        {
            value: 'standard',
            label: 'Standard Clean'
        },
        {
            value: 'deep',
            label: 'Deep Clean'
        },
        {
            value: 'move-in/out',
            label: 'Move In/Out Clean'
        },
    ],
    cleaning_type_only_standard: [
        {
            value: 'none',
            label: 'None'
        },
        {
            value: 'standard',
            label: 'Standard Clean'
        }
    ],
    frequency: [
        'One-time', 'Weekly', 'Biweekly', 'Monthly'
    ],
    about_us: [
        'Google', 'Social Media', 'Referral'
    ],
    states: [
        {
            name: "Delaware",
            abbreviation: "DE"
        },
        {
            name: "Pennsylvania",
            abbreviation: "PA"
        },
    ],
}

export default formData;