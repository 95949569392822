import React from 'react'
import Container from 'react-bootstrap/Container';
import PageTitle from '../components/PageTitle';
import { Link } from 'react-router-dom'

export default function Terms(){
    return(
        <Container className='terms'>
            <PageTitle title='DHCS - Terms & Conditions' />

            <h1>Terms & Conditions</h1>
            <p>Dani's Home Cleaning Services, LLC offers exceptional residential & commercial cleaning services. Our professional cleaning teams will keep your home or office looking beautifully clean. By using this website to book service, you agree to comply to be bound by these terms & conditions. If you do not agree to these terms & conditions, do not book service with us.</p>
            
            <div>
                <p>1. Booking Confirmation</p>
                <p>Booking service online doesn’t guarantee you a spot for that date/time, you will be contacted by email or phone with a confirmation. In order to reserve your cleaning date and time, a deposit of $70 will be applied. This fee is NON-REFUNDABLE but deductible from the total cleaning price.</p>
                <p><span className='fw-500'>WE REQUIRE AT LEAST 24 HOURS OF NOTICE</span> (this is to secure jobs for our cleaners for the next day and will give us time to find a replacement job in case you cancel or reschedule)</p>
                <p>The $70 booking confirmation fee will automatically convert into a cancellation fee in case you fail to notify us 24 hours before your scheduled appointment. The $70 is non-refundable which means you will have to pay another deposit when booking again.</p>
                <p>The client can only reschedule their cleaning 24 hours prior to their scheduled appointment with a maximum of 5 counts. More than 5 will forfeit their $70 and will automatically convert it to a cancellation fee.</p>
            </div>

            <div>
                <p>2. Cleaning Crew</p>
                <p>We always work with teams of two, occasionally more cleaners may be needed due to the complexity of work or busy schedule. We do our best to limit the rotation of cleaners in your home while still providing sufficient cross-training to our cleaning technicians. Our employees have been rigorously background screened & drug tested prior to working with us, you can rest assured all our staff has integrity and is held accountable.</p>
            </div>
            
            <div>
                <p>3. Right To Refuse Service</p>
                <p>We reserve the right to deny &/or terminate service because of safety concerns, inappropriate or uncomfortable situations, weapons on-premises, severe clutter & disconnected utilities. Our employees have the choice to leave if the home is in an extremely unsanitary condition or if they feel unsafe/threatened. If you book a cleaning that is unreasonable, the cleaners may refuse service on the spot & you will be charged the cancellation fee.</p>
            </div>
            
            <div>
                <p>4. Cleaning Day Preparation</p>
                <p>Your price for cleaning is based on the cleaners focusing all of their time on cleaning, we ask that you take a few minutes to tidy up to allow the cleaners easy access to the areas/surfaces to be cleaned: floors, countertops, tabletops, etc. If you’d like our cleaners to do these tasks for you, call the office in advance so your cleaning fee can be adjusted for the additional time.</p>
            </div>
            
            <div>
                <p>5. Parking</p>
                <p>Please provide parking convenience for Service Providers. If there's no free parking available, we will charge your card the parking fee. If there's no parking available the appointment will be considered “cancelled” and the $70 cancellation fee will be applied if Service Providers have to leave due to parking inconvenience.</p>
            </div>
            
            <div>
                <p>6. Add-On Services (These Services Are Available For An Extra Charge)</p>
                <ul>
                    <li>Using Eco-friendly Products</li> 
                    <li>Clean Interior Windows</li>
                    <li>Detail Blinds</li>
                    <li>Detail Baseboards</li>
                    <li>Hand Wash 1 Sink Full of Dishes  </li>
                    <li>Clean Inside Kitchen Cabinets  </li>
                    <li>Clean Inside Oven</li>
                    <li>Clean Inside Fridge</li>
                    <li>Pets (hair)</li>
                    <li>Remove Mold/Mildew in Shower & Tub  </li>
                    <li>Clean Mattress</li>
                    <li>Sweep Inside Garage  </li>
                    <li>Patio Furniture Cleaning Dust/Wipe  </li>
                    <li>Carpet Washing </li>
                </ul>
            </div>
            
            <div>
                <p>7. Pets</p>
                <p>We will gladly work around pets. We ask that indoor activity be limited for cleaning efficiency & safety reasons, if your pet becomes anxious or presents a safety concern, Dani's Home Cleaning Services, LLC reserves the right to remove its employees from your home.</p>
                <p>Our employees are instructed not to enter a house if they believe an animal is a threat, pets may behave differently if a family member is not present. If the removal of our cleaning technician is due to aggressive pets, our cancellation policy will apply.</p>
            </div>
            
            <div>
                <p>8. Service Fees</p>
                <p>Please remember we give these instant prices based on years of experience, but we may adjust the price based on the actual condition of the house. To ensure there are no surprises when we arrive & find that the job will require extra time we’ll call you to let you know if we are unable to reach you, the crew will have to leave & you will be charged the cancellation fee.</p>
                <p>We reserve the right to reevaluate rates at any time based on the time it takes to perform our service to meet the client’s standards, Dani's Home Cleaning Services, LLC will contact the client to discuss price or service revisions if the cleaning time differs drastically from the original bid.</p>
            </div>
            
            <div>
                <p>9. Payments</p>
                <p>Payments must be made on the day of cleaning by check, cash, or credit card</p>
            </div>
            
            <div>
                <p>11. Inclement Weather</p>
                <p>We will be closed for business when weather conditions prevent School Districts from opening.</p>
            </div>
            
            <div>
                <p>12. Keys</p>
                <p>At Dani's Home Cleaning Services, LLC, we prioritize the security of our clients' homes. Instead of us holding the keys, we kindly request that our customers provide a lockbox where you can store your house key securely inside the lockbox outside your residence. Once we finish the cleaning service, we will return the key to the lockbox and lock it securely. By following this procedure, we aim to ensure the safety of our client's homes while still providing convenient access for our cleaning team. In the unfortunate event that a client chooses to leave a door unlocked or place a key in an unsecured location, Dani's Home Cleaning Services, LLC cannot be held liable for any damages or theft that may occur.</p>
            </div>
            
            <div>
                <p>13. Credits</p>
                <p>Attribution for third-party content used on this site, including icons, images, and other resources, can be found on our <Link to='/credits' className='link'>Credits page</Link></p>
            </div>
            
            <div>
                <p>14. Non-Solicitation Of Dani's Home Cleaning Services, LLC Employees</p>
                <p>As our customer, we ask that you agree not to solicit or hire any of our cleaning employees to work directly for you. Our professional cleaners are background checked, their references verified & have completed comprehensive cleaning training. Significant time, resources & money are invested in each new employee before we allow them to enter our customer’s homes.</p>
            </div>
            
            <div>
                <p>15. Governing Law</p>
                <p>Any claim relating to Dani's Home Cleaning Services, LLC website shall be governed by the laws of the State of Delaware without regard to its conflict of law provisions.</p>
                <p>We have made every effort to design our website to be useful, informative, helpful & honest, hopefully, we have accomplished that & would ask that you let us know if you would like to see improvements or changes that would make it even easier for you to find the information you need.</p>
                <p>Dani's Home Cleaning Services, LLC reserves the right to change these terms & conditions at any time without prior notice to you.</p>
                <p>©️ 2024 Dani's Home Cleaning Services, LLC</p>
            </div>
            
            <p className='fw-600'>Last updated: 12/07/2024</p>
        </Container>
    )
}