import React from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Field, ErrorMessage } from 'formik';
import formData from '../../quoteUtils/formData';
import cleaningAreas from '../../quoteUtils/cleaningAreas';

const WithoutQuantity = ({touched, errors}) => {
    return (
        <>
            <p>Select your preferred cleaning service for each room</p>
            <Row>
                { cleaningAreas.withoutQuantity.map(item => (
                    <Col sm={12} md={6} lg={4} key={item.name} className='quote--withoutQuantity'>
                        <section className='my-3'>
                            <label htmlFor={item.name} className='fw-600'>{item.label}</label>
                            <Field
                                as="select"
                                id={item.name}
                                name={item.name}
                                className={`form-control ${touched[item.name] && errors[item.name] ? 'is-invalid' : ''}`}
                            >
                                { item.type === 'always'?
                                    formData['cleaning_type'].map((cleaning, index) => (
                                        <option key={cleaning.value || index} value={cleaning.value}>
                                            {cleaning.label}
                                        </option>
                                    ))
                                    :formData['cleaning_type_none'].map((cleaning, index) => (
                                        <option key={cleaning.value || index} value={cleaning.value}>
                                            {cleaning.label}
                                        </option>
                                    ))
                                }
                            </Field>
                            <div className='my-3 form-check'>
                                <Field type="checkbox" name={`${item.name}_spot_wall`}  className='form-check-input' id={`${item.name}_spot_wall`} />
                                <label htmlFor={`${item.name}_spot_wall`} className='form-check-label' >Spot Wall Cleaning</label>
                            </div>
                            <ErrorMessage name={item.name}>
                                {msg => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                        </section>
                    </Col>
                ))}
                { cleaningAreas.onlyStandard.map(item => (
                    <Col sm={12} md={6} lg={4} key={item.name} className='quote--withoutQuantity'>
                        <section className='my-3'>
                            <label htmlFor={item.name} className='fw-600'>{item.label}</label>
                            <Field
                                as="select"
                                id={item.name}
                                name={item.name}
                                className={`form-control ${touched[item.name] && errors[item.name] ? 'is-invalid' : ''}`}
                            >
                                { 
                                    formData['cleaning_type_only_standard'].map((cleaning, index) => (
                                        <option key={cleaning.value || index} value={cleaning.value}>
                                            {cleaning.label}
                                        </option>
                                    ))
                                }
                            </Field>
                            <ErrorMessage name={item.name}>
                                {msg => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                        </section>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default WithoutQuantity;